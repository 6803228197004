import "./index.scss";
// import {check_operator} from './../mob_operator/index';

// let array_oper_number = [39, 50, 63, 66, 67, 68, 73, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99, 44];
// let array_error = check_operator(array_oper_number);

$(function () {
  Onload();
});

/**
 * valide_form - Валідація форм
 * @param {selector form} ID Форми на яку підвішують валідацію
 * @param {class name} class групи куди виводять помилки
 * @param {bull} true Чи виводи вспливайку пісял відповіді ajax
 *
 **/

function Onload() {
  // valide_form('#brief_st', '.input', false);
  // valide_form('#contacts', '.input', true);
  // valide_form('#feedback_smal', '.input', true);
  // valide_form('#contacts', '.input', true);
  valide_form("#form_info_question", ".input", true);
  valide_form("#discuss_project", ".input", true);
  valide_form("#fast_call", ".input", true);
  valide_form("#contact_form", ".input", true);
  valide_form("#user_info_form", ".input", false);
  valide_form("#services__ideas_form", ".input", true);
  valide_form("#userLogin", ".input", false);
  valide_form("#registerUser", ".input", false);

  valide_form("#formOrderShop", ".input", true);

  // console.log("form_info_question");
}

function location_leng() {
  return location.pathname.split("/")[1];
}

let lang_site;
let curr_lang;
let curr_lang_length;
lang_site = location_leng();
switch (lang_site) {
  case "uk":
    curr_lang = "Поле повинно містити лише букви";
    curr_lang_length = "Поле повинно містити більше 6 символів";
    break;
  case "ru":
    curr_lang = "Поле должно содержать только буквы";
    curr_lang_length = "Поле должно содержать более 6 символов";

    break;
  case "en":
    curr_lang = "The field must contain only letters";
    curr_lang_length = "Field must contain more than 6 characters";

    break;
  default:
    curr_lang = "Поле повинно містити лише букви.";
    curr_lang_length = "Поле повинно містити більше 6 символів";
}

jQuery.validator.addMethod(
  "lettersonly",
  function (value, element) {
    return this.optional(element) || /[^0-9]+$/i.test(value);
  },
  curr_lang
);

jQuery.validator.addMethod(
  "minLength",
  function (value, element) {
    if (value.length < 6) {
      return false;
    } else {
      return true;
    }
  },
  curr_lang_length
);

function valide_form(id_form, append_error_box, check_request) {
  var check_request = check_request;
  if ($(id_form).length > 0) {
    var lang_site;
    var errore_text = {};

    let check_pass = true;

    // if (id_form == "registerUser" || id_form == "registerUser") {
    //   check_pass = false;
    // } else {
    //   check_pass = true;
    // }

    lang_site = location_leng();
    switch (lang_site) {
      case "uk":
        errore_text.required = "Поле обов'язково для заповнення";
        errore_text.email = "Поле має містити email";
        errore_text.rating = "Оцінка товару є обов'язкова";
        break;
      case "ru":
        errore_text.required = "Поле обязательно для заполнения";
        errore_text.email = "Поле должно содержать email";
        errore_text.rating = "Оценка товара является обязательная";

        break;
      case "en":
        errore_text.required = "The field is required";
        errore_text.email = "The field must contain an email";
        errore_text.rating = "Evaluation of the goods is required";

        break;
      default:
        errore_text.required = "Поле обов'язково для заповнення";
        errore_text.email = "Поле має містити email";
        errore_text.rating = "Оцінка товару є обов'язкова";
    }

    $(id_form).validate({
      errorPlacement: function (event, validator) {
        console.log(event);
        console.log(append_error_box);

        $(validator).parents(append_error_box).append($(event));
        $(validator).parents(append_error_box).addClass("is-error");
      },
      ignore: "not:hidden.product_rating",
      rules: {
        name: {
          required: true,
          lettersonly: true,
        },
        username: {
          required: true, 
        },
        password: {
          required: true,
          minLength: check_pass,
        },
        password2: {
          required: true,
          minLength: check_pass,
        },
        email: {
          required: true,
          email: true,
        },
        user_last_name: {
          required: true,
          lettersonly: true,
        },
        payment: {
          required: true,
        },
        phone: {
          required: true,
        },

        pass: {
          required: true,
          minLength: check_pass,
        },
        repeat_pass: {
          required: true,
          minLength: check_pass,
        },
      },

      messages: {
        name: {
          required: errore_text.required,
        },
        username: {
          required: errore_text.required,
        },
        password: {
          required: errore_text.required,
        },
        password2: {
          required: errore_text.required,
        },

        phone: {
          required: errore_text.required,
        },

        email: {
          required: errore_text.required,
          email: errore_text.email,
        },
        user_last_name: {
          required: errore_text.required,
        },
        payment: {
          required: errore_text.required,
        },
      },

      submitHandler: function (form) {
        console.log("event", event);

        event.preventDefault();
        console.log("check_request", check_request);

        class SendFormData {
          constructor(form, showModalRequest) {
            this.form = form;
            this.showModalRequest = showModalRequest;
          }

          simpleSerializeData() {
            let form = this.form;
            let serialize = $(form).serializeArray();

            let data = {};

            $(serialize).each(function (index, input) {
              data[input.name] = input.value;
            });

            return data;
          }
          errorSendForm(typeErroe, formId) {
            switch (typeErroe) {
              case "NotURL":
                throw `URL Form is #${formId} not found `;
                break;

              default:
                break;
            }
          }
          addLoaderSend() {
            $(".loader_all").addClass("loader_all-active");
            var loader =
              '<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
            document.getElementById("loader_all__wrap").innerHTML = loader;
          }
          deleteLoaderSend() {
            $(".loader_all").removeClass("loader_all-active");
          }
          showModalSuccessful() {
            $.fancybox.open({
              src: "#successfulSending",
              touch: false,
            });
          }
          showModalError() {
            $.fancybox.open({
              src: "#errorSending",
              touch: false,
            });
          }
          сlosingModal(time) {
            setTimeout(function () {
              $.fancybox.close();
            }, time);
          }
          clearInput() {
            const TYPE_INPUT = {
              TEXT: "text",
              EMAIL: "email",
              CHECKBOX: "checkbox",
              PASSWORD: "password",

              TEL: "tel",
              FILE: "file",
            };
            let form = this.form;
            const all_form_input = form.querySelectorAll("input");
            const all_form_textaria = form.querySelectorAll("textarea");

            for (const key in all_form_textaria) {
              if (all_form_textaria.hasOwnProperty(key)) {
                const element = all_form_textaria[key];
                element.value = "";
              }
            }

            for (const key in all_form_input) {
              if (all_form_input.hasOwnProperty(key)) {
                const element = all_form_input[key];

                switch (element.type) {
                  case TYPE_INPUT.TEXT:
                    element.value = "";
                    break;
                  case TYPE_INPUT.EMAIL:
                    element.value = "";
                    break;
                  case TYPE_INPUT.TEL:
                    element.value = "";
                    break;
                  case TYPE_INPUT.FILE:
                    element.value = "";
                    break;
                  case TYPE_INPUT.PASSWORD:
                    element.value = "";
                    break;

                  case TYPE_INPUT.CHECKBOX:
                    $(element).prop("checked", false);
                    break;
                }
              }
            }
          }
          sendingDataAjax(urlSendForm, data) {
            let self = this;

            console.log(!!self.showModalRequest);

            fetch(urlSendForm, {
              method: "POST",
              body: new URLSearchParams($.param(data)),
            })
              .then((data) => {
                return data.json();
              })
              .then((data) => {
                let self = this;

                self.deleteLoaderSend();
                if (!!self.showModalRequest) {
                  self.clearInput();
                }

                if (
                  data.status == "OK" &&
                  typeof data["status"] !== "undefined"
                ) {
                  if (
                    typeof data["redirect_url"] !== "undefined" &&
                    data.redirect_url != ""
                  ) {
                    console.log("redirect_url");

                    location.href = data.redirect_url;
                  } else if (
                    typeof data["url"] !== "undefined" &&
                    data.url != ""
                  ) {
                    console.log("url");
                    location.href = data.url;
                  } else {
                    console.log("url_12321");

                    $.fancybox.close();
                    if (!!self.showModalRequest) {
                      self.showModalSuccessful();
                    }
                  }
                } else if (
                  typeof data["url"] !== "undefined" &&
                  data.url != ""
                ) {
                  console.log("url");
                  location.href = data.url;
                } else {
                  $.fancybox.close();
                  if (!!self.showModalRequest) {
                    self.showModalError();
                  }

                  if (
                    self.form.id === "userLogin" ||
                    self.form.id === "registerUser"
                  ) {
                    if (!!data.error_fields) {
                      for (const key in data.error_fields) {
                        if (data.error_fields.hasOwnProperty(key)) {
                          let textEror = data.error_fields[key];
                          $(`#${form.id}`)
                            .find(`input[name='${key}']`)
                            .parents(".input")
                            .append(`<label class="error">${textEror}</label>`);
                        }
                      }
                    }
                  }
                }

                self.сlosingModal(2500);
              })
              .catch((err) => {
                $.fancybox.close();
                self.deleteLoaderSend();
                self.showModalError();
                self.сlosingModal(2500);
                throw `Помилка відповіді сервера: ${err}`;
              });
          }
          sendingData(data) {
            let form = this.form;
            let idForm = $(form).attr("id");
            let urlSendForm = this.form.action;
            let urlCarentPage = location.href;
            console.log(urlSendForm);
            console.log(!!urlSendForm);

            if (!!urlSendForm && urlCarentPage !== urlSendForm) {
              console.log("urlSendForm");

              this.addLoaderSend();
              this.sendingDataAjax(urlSendForm, data);
            } else {
              console.log("NOT urlSendForm");
              this.showModalError();

              this.errorSendForm("NotURL", idForm);
              this.сlosingModal(2500);
            }
          }
          sendForm() {
            let dataForm = this.simpleSerializeData();
            this.sendingData(dataForm);
          }
          run() {
            this.sendForm();
          }
        }

        class ShopOrder extends SendFormData {
          checkErrorDelivery() {
            let dataForm = this.simpleSerializeData();
            let errorFlag = 0;
            if (
              !!dataForm["delivery_opt"] &&
              dataForm["delivery_opt"] == "NovaPoshta"
            ) {
              let createError = `<label id="delivery_department-error " class="select_label error" for="">${errore_text.required}</label>`;

              if (
                $('select[name="delivery_sity"]').find("option:selected")
                  .length < 1
              ) {
                errorFlag++;
                $('select[name="delivery_sity"]')
                  .parents(".input")
                  .append($(createError));
                $('select[name="delivery_sity"]')
                  .parents(".input")
                  .addClass("is-error");
              }

              if (
                $('select[name="delivery_department"]').find("option:selected")
                  .length < 1
              ) {
                errorFlag++;
                $('select[name="delivery_department"]')
                  .parents(".input")
                  .append($(createError));
                $('select[name="delivery_department"]')
                  .parents(".input")
                  .addClass("is-error");
              }
            }

            if (errorFlag > 0) {
              return false;
            } else {
              return true;
            }
          }

          simpleSerializeDataShop() {
            let dataForm = this.simpleSerializeData();
            let erroDellivery = this.checkErrorDelivery();

            if (!!erroDellivery) {
              let dataForm = this.simpleSerializeData();
              if (
                !!dataForm["delivery_opt"] &&
                dataForm["delivery_opt"] == "NovaPoshta"
              ) {
                if (
                  $('select[name="delivery_sity"]').find("option:selected")
                    .length > 0 &&
                  $('select[name="delivery_department"]').find(
                    "option:selected"
                  ).length > 0
                ) {
                  dataForm[
                    "address"
                  ] = `${dataForm.delivery_sity}, ${dataForm.delivery_department}`;
                  console.log("91011");
                  return dataForm;
                } else {
                  console.log("678");
                  return false;
                }
              } else {
                console.log("123");
                return dataForm;
              }
            } else {
              console.log("456");
              return false;
            }
          }

          run() {
            let dataForm = this.simpleSerializeDataShop();
            console.log(dataForm);
            console.log(!!dataForm);

            if (!!dataForm) {
              console.log(dataForm);

              this.sendingData(dataForm);
            }
          }
        }

        if (form.id == "formOrderShop") {
          let shopOrder = new ShopOrder(form, check_request);
          shopOrder.run();
          console.log("123123");
        } else {
          let sendFormData = new SendFormData(form, check_request);

          sendFormData.run();
        }
      },
    });
  }
}

let inputsRegister = $("#registerUser").find("input");
[...inputsRegister].map((item) => {
  $(item).on("focus", function () {
    $(this).parents(".input").find("label").remove();
  });
});


let inputsLogin = $("#userLogin").find("input");
[...inputsLogin].map((item) => {
  $(item).on("focus", function () {
    $(this).parents(".input").find("label").remove();
  });
});
