// script interface
import '../components/common_componentc/normalize/index'
import '../components/interface/grid/index'
import '../components/interface/form/index'
import '../components/interface/button'

import './../components/module/loader/index'


// // script common elements
import '../components/module/form_errors'
import './../components/common_componentc/admin_panel/index'
import '../components/common_componentc/breadcrumbs/index'
import '../components/common_componentc/box_info/index'


// // script pages
import '../components/common_componentc/header/index'
import '../components/common_componentc/footer/index'

import '../components/pages/main/index'

 