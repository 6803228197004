import './index.scss'
 


$('.navbar_footer').find('.header').on('click',function(){
    if(!$(this).parents('.navbar_footer').hasClass('active')){
        $(this).parents('.navbar_footer').addClass('active')
    }else{
        $(this).parents('.navbar_footer').removeClass('active')
    }
})


$('.btn_discuss_project').on('click',function(event){
    event.preventDefault();
    $.fancybox.open({
        src: '#discuss_project',
        touch: false
    });
})
$('.btn_fast_call').on('click',function(event){
    event.preventDefault()
    $.fancybox.open({
        src: '#fast_call',
        touch: false
    });
})




 